<template>
  <div style="position: relative; min-height: 100vh;">
    <mx-preloader :loader-status="preloader"/>
    <MXContainerWrapper>
      <v-container fluid v-if="!preloader">
        <v-row class="mx-task-top_bar py-2">
          <v-col cols="12">
            <div class="d-flex align-center justify-space-between">
             <div class="d-flex align-center">
               <h3 class="ml-1 mr-8">{{ $t('task.title') }}</h3>
<!--               <div class="d-flex align-center">-->
<!--                 <v-progress-circular-->
<!--                     :rotate="-90"-->
<!--                     :size="66"-->
<!--                     :width="13"-->
<!--                     :value="taskInPercent"-->
<!--                     :color="color"-->
<!--                     class="mr-3"-->
<!--                 >-->
<!--                   {{ taskInPercent }}-->
<!--                 </v-progress-circular>-->
<!--                 <div class="d-flex flex-column">-->
<!--                   <span style="font-size: 13px;">{{ $t('task.status.todo') }} (<strong>{{ $store.getters.getTodoTasks.length }}</strong>)</span>-->
<!--                   <span style="font-size: 13px;">{{ $t('task.status.in_progress') }} (<strong>{{ $store.getters.getInProgressTasks.length }}</strong>)</span>-->
<!--                   <span style="font-size: 13px;">{{ $t('task.status.completed') }} (<strong>{{ $store.getters.getCompletedTasks.length }}</strong>)</span>-->
<!--                 </div>-->
<!--               </div>-->
               <div class="d-flex flex-column mx-10" style="width: 100%; max-width: 480px;">
                 <v-progress-linear
                     v-model="taskInPercent"
                     height="20"
                     :color="color"
                     rounded
                 >
                   <strong style="font-size: 12px;">{{ taskInPercent }}%</strong>
                 </v-progress-linear>
                 <div class="d-flex mx-task-top-links">
                   <span @click="goto('todo')" class="mx-task-top-link" :class="{'mx-task-top-link-active': $store.getters.getTodoTasks.length > 0}">
                     {{ $t('task.status.todo') }} (<strong>{{ $store.getters.getTodoTasks.length }}</strong>)
                   </span>
                   <span @click="goto('in_progress')" class="mx-task-top-link" :class="{'mx-task-top-link-active': $store.getters.getInProgressTasks.length > 0}">
                     {{ $t('task.status.in_progress') }} (<strong>{{ $store.getters.getInProgressTasks.length }}</strong>)
                   </span>
                   <span @click="goto('completed')" class="mx-task-top-link" :class="{'mx-task-top-link-active': $store.getters.getCompletedTasks.length > 0}">
                     {{ $t('task.status.completed') }} (<strong>{{ $store.getters.getCompletedTasks.length }}</strong>)
                   </span>
                 </div>
               </div>
             </div>
              <div>
                <v-btn color="primary" @click="$store.commit('setStatusTaskDrawer', true)">
                  <v-icon size="18">fas fa-plus</v-icon>
                </v-btn>
                <v-btn @click="tasksReload" class="ml-2">
                  <v-icon color="primary" size="18">fas fa-redo</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="mx-scrolchesh" ref="todo" cols="12" v-if="$store.getters.getTodoTasks.length !== 0">
            <TaskTableComponent block_name="todo" :tasks="$store.getters.getTodoTasks"/>
          </v-col>

          <v-col cols="12" id="in_progress" ref="in_progress" v-if="$store.getters.getInProgressTasks.length !== 0">
            <TaskTableComponent block_name="in_progress" :tasks="$store.getters.getInProgressTasks"/>
          </v-col>

          <v-col cols="12" id="completed" ref="completed" v-if="$store.getters.getCompletedTasks.length !== 0">
            <TaskTableComponent block_name="completed" :tasks="$store.getters.getCompletedTasks"/>
          </v-col>
        </v-row>
        <div style="height: 65vh;"></div>
      </v-container>
    </MXContainerWrapper>
  </div>

</template>

<script>
import env from '../env';
import MXContainerWrapper from '../components/MXContainerWrapper';
import UserAvatarComponent from "@/module/task/components/UserAvatarComponent";
import TaskImportanceLabelComponent from "@/module/task/components/Importance/TaskImportanceLabelComponent";
import TaskTableComponent from "@/module/task/components/Task/TaskTableComponent";
import moment from "moment";

export default {
  name: `${env.view.name}MainView`,
  components: {MXContainerWrapper, UserAvatarComponent, TaskImportanceLabelComponent, TaskTableComponent},
  data() {
    return {
      preloader: true,
      scroll_element: 'todo',
      abw: {
        count_day: 28,
        month: '2',
        persons: [
          {
            id: 1,
            name: 'Alexander',
            abws: [
              {start_day: '2022-02-20', end_day: '2022-02-22', count_day: 3, status: 'kr'},
              {start_day: '2022-02-10', end_day: '2022-02-10', count_day: 1, status: 'ur'},
              {start_day: '2022-01-24', end_day: '2022-02-02', count_day: 10, status: 'ur'},
            ]
          },
          {
            id: 2,
            name: 'Ewgenij',
            abws: [
              {start_day: '2022-02-13', end_day: '2022-02-17', count_day: 5, status: 'ur'},
              {start_day: '2022-02-01', end_day: '2022-02-03', count_day: 3, status: 'ur'},
              {start_day: '2022-04-07', end_day: '2022-04-14', count_day: 8, status: 'ur'},
            ]
          }
        ]
      }
    }
  },
  created() {
    this.tasksReload()
  },
  computed: {

    color() {
      if (this.taskInPercent >= 85) {
        return 'teal'
      } else if (this.taskInPercent >= 65) {
        return 'amber'
      } else {
        return 'red'
      }
    },
    taskInPercent() {
      const todo = this.percentTasksFilter(this.$store.getters.getTodoTasks, true).length
      const in_progress = this.percentTasksFilter(this.$store.getters.getInProgressTasks, false).length
      const completed = this.$store.getters.getTodoTasks.length + this.$store.getters.getInProgressTasks.length
      const result = (todo + in_progress + completed)

      if (completed === 0) {
        return 100
      }

      return parseInt(completed * 100 / result)
    },
  },
  methods: {
    activeElement(element) {
      return this.scroll_element === element ? 'primary': 'default'
    },
    goto(refName) {
      const element = this.$refs[refName];
      const top = element.offsetTop + -90;


      this.scroll_element = refName

      window.scrollTo({
        top: top,
        left: 0,
        behavior: 'smooth'
      });
    },
    tasksReload() {
      this.$store.dispatch('myTask').then(() => {
        setTimeout(() => {
          this.preloader = false
        }, 300)
      })
    },
    percentTasksFilter(tasks, isSame = false) {
      return tasks.filter(task => {
        return this.getEndDateStatus(task.end_time, isSame)
      })
    },
    getEndDateStatus(date, isSame) {
      if (isSame) {
        return moment(moment().format('YYYY-MM-DD')).isAfter(date)
            || moment(moment().format('YYYY-MM-DD')).isSame(date)
      }
      return moment(moment().format('YYYY-MM-DD')).isAfter(date)
    },
  }
}
</script>

<style lang="scss">
.mx-task-scroll-elements {
  border-radius: 4px
}
.mx-task-top_bar {
  position: sticky;
  top: 48px;
  z-index: 1;
}

.mx-task-top-links {
  margin-top: 6px;
  width: 100%;
  min-width: 480px;
  display: flex;
  align-items: center;

  .mx-task-top-link {
    font-size: 13px;
    margin: 0 15px 0 0;
    &-active {
      cursor: pointer;
      //border-bottom: 1px solid;
      text-decoration: underline;
    }
  }
}



.theme--light {
  .mx-task-top_bar{
    background: #ffffff;
    //background: #ffc107;
    box-shadow: 0 0 24px rgba(169, 194, 209, 0.3), 0 0 4px rgba(169, 194, 209, 0.2);
  }
  .mx-task-scroll-elements {
    background: #f3f3f3;
  }
  .mx-task-top-links {
    .mx-task-top-link {
      &-active {
        color: #fc8b5d;
        border-color: #fc8b5d;
        &:hover {
          color: #f8682e;
          border-color: #f8682e;
        }
      }
    }
  }
}

.theme--dark {
  .mx-task-top_bar{
    background: #1e1e1e;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
  .mx-task-scroll-elements {
    background: #232323;
  }
  .mx-task-top-links {
    .mx-task-top-link {
      &-active {
        color: #126d67;
        border-color: #126d67;
        &:hover {
          color: #0f6963;
          border-color: #0f6963;
        }
      }
    }
  }
}

</style>
